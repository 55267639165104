/* eslint-disable react/prop-types */
import React, { useState, useContext, useMemo } from 'react';
import classes from './FAB.module.css';

const IsOpenContext = React.createContext();

const Container = ({ children }) => {
  const [open, setOpen] = useState(false);
  const value = useMemo(() => ({ open, setOpen }), [open, setOpen]);
  return (
    <nav className={`print:hidden ${classes.container} ${open ? classes.containerOpen : ''}`}>
      <IsOpenContext.Provider value={value}>
        {children}
      </IsOpenContext.Provider>
    </nav>
  );
};

const Button = ({
  className, rotate, tooltip, icon, children,
}) => {
  const { open, setOpen } = useContext(IsOpenContext);
  return (
    <button
      onClick={() => {
        setOpen(!open);
      }}
      type="button"
      className={`text-white ${classes.button} ${classes.item} ${className} ${rotate && open ? classes.rotate : ''}`}
      tooltip={tooltip}
    >
      <span className="sr-only">Abrir opciones de envío de mensaje</span>
      {!open && icon && <i className={icon} />}
      {open
        ? (
          <svg
            viewBox="-9 -9 41 41"
            style={{
              width: '100%', height: '100%', fill: 'rgb(255, 255, 255)', stroke: 'none',
            }}
          >
            <path d=" M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
          </svg>
        )
        : children}
    </button>
  );
};

const Link = ({
  href, className, rotate, tooltip, icon, children, targetBlank,
}) => (
  <a
    {...(targetBlank ? { target: '_blank' } : {})}
    href={href}
    className={`text-white ${classes.item} ${classes.link} ${className} ${rotate ? classes.rotate : ''}`}
    tooltip={tooltip}
  >
    {icon && <i className={icon} />}
    {children}
  </a>
);

export { Container, Link, Button };
