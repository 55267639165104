import React from 'react';
import { Container, Button, Link } from './FloatingButtons';

const Chat = () => (
  <Container>
    <Link
      href="https://www.instagram.com/lastraperasperu/"
      tooltip="Instagram"
      className="bg-[#F77737]"
      targetBlank
    >
      <span className="sr-only">Ir a Instagram</span>
      <svg
        viewBox="0 0 32 32"
        style={{
          width: '100%',
          height: '100%',
          fill: 'rgb(255, 255, 255)',
          stroke: 'none',
        }}
      >
        <path d="m20.97355,5l-9.88537,0c-3.35702,0 -6.08818,2.73129 -6.08818,6.08831l0,9.88537c0,3.35715 2.73116,6.08831 6.08818,6.08831l9.88537,0c3.35728,0 6.08844,-2.73129 6.08844,-6.08831l0,-9.88537c0.00013,-3.35702 -2.73116,-6.08831 -6.08844,-6.08831zm4.13113,15.97368c0,2.27782 -1.85318,4.13087 -4.131,4.13087l-9.8855,0c-2.27769,0.00013 -4.13074,-1.85305 -4.13074,-4.13087l0,-9.88537c0,-2.27769 1.85305,-4.13087 4.13074,-4.13087l9.88537,0c2.27782,0 4.131,1.85318 4.131,4.13087l0,9.88537l0.00013,0z" />
        <path d="m16.031,10.34644c-3.13466,0 -5.68482,2.55016 -5.68482,5.68482c0,3.13453 2.55016,5.68456 5.68482,5.68456c3.13466,0 5.68482,-2.55003 5.68482,-5.68456c0,-3.13466 -2.55016,-5.68482 -5.68482,-5.68482zm0,9.4118c-2.05519,0 -3.72737,-1.67192 -3.72737,-3.72711c0,-2.05532 1.67205,-3.72737 3.72737,-3.72737c2.05532,0 3.72737,1.67205 3.72737,3.72737c0,2.05519 -1.67218,3.72711 -3.72737,3.72711z" />
        <path d="m21.95423,8.68666c-0.37713,0 -0.74761,0.15268 -1.01396,0.4202c-0.26765,0.26621 -0.4215,0.63682 -0.4215,1.01526c0,0.37727 0.15399,0.74774 0.4215,1.01526c0.26621,0.26621 0.63682,0.4202 1.01396,0.4202c0.37844,0 0.74774,-0.15399 1.01526,-0.4202c0.26752,-0.26752 0.4202,-0.63813 0.4202,-1.01526c0,-0.37844 -0.15268,-0.74905 -0.4202,-1.01526c-0.26621,-0.26752 -0.63682,-0.4202 -1.01526,-0.4202z" />
      </svg>
    </Link>
    <Link
      href="https://m.me/259781560727144"
      tooltip="Facebook Messenger"
      className="bg-[#006AFF]"
      targetBlank
    >
      <span className="sr-only">Ir a Facebook Messenger</span>
      <svg
        viewBox="0 0 32 32"
        style={{
          width: '100%', height: '100%', fill: 'rgb(255, 255, 255)', stroke: 'none',
        }}
      >
        <path d="M16 6C9.925 6 5 10.56 5 16.185c0 3.205 1.6 6.065 4.1 7.932V28l3.745-2.056c1 .277 2.058.426 3.155.426 6.075 0 11-4.56 11-10.185C27 10.56 22.075 6 16 6zm1.093 13.716l-2.8-2.988-5.467 2.988 6.013-6.383 2.868 2.988 5.398-2.987-6.013 6.383z" />
      </svg>
    </Link>
    <Link
      href="https://wa.me/51959628837?text=Hola%20Traperas"
      tooltip="Whatsapp"
      className="bg-[#25D366]"
      targetBlank
    >
      <span className="sr-only">Ir a Whatsapp</span>
      <svg
        viewBox="0 0 32 32"
        style={{
          width: '100%', height: '100%', fill: 'rgb(255, 255, 255)', stroke: 'none',
        }}
      >
        <path d="M19.11 17.205c-.372 0-1.088 1.39-1.518 1.39a.63.63 0 0 1-.315-.1c-.802-.402-1.504-.817-2.163-1.447-.545-.516-1.146-1.29-1.46-1.963a.426.426 0 0 1-.073-.215c0-.33.99-.945.99-1.49 0-.143-.73-2.09-.832-2.335-.143-.372-.214-.487-.6-.487-.187 0-.36-.043-.53-.043-.302 0-.53.115-.746.315-.688.645-1.032 1.318-1.06 2.264v.114c-.015.99.472 1.977 1.017 2.78 1.23 1.82 2.506 3.41 4.554 4.34.616.287 2.035.888 2.722.888.817 0 2.15-.515 2.478-1.318.13-.33.244-.73.244-1.088 0-.058 0-.144-.03-.215-.1-.172-2.434-1.39-2.678-1.39zm-2.908 7.593c-1.747 0-3.48-.53-4.942-1.49L7.793 24.41l1.132-3.337a8.955 8.955 0 0 1-1.72-5.272c0-4.955 4.04-8.995 8.997-8.995S25.2 10.845 25.2 15.8c0 4.958-4.04 8.998-8.998 8.998zm0-19.798c-5.96 0-10.8 4.842-10.8 10.8 0 1.964.53 3.898 1.546 5.574L5 27.176l5.974-1.92a10.807 10.807 0 0 0 16.03-9.455c0-5.958-4.842-10.8-10.802-10.8z" />
      </svg>
    </Link>
    <Button
      icon="fas fa-comments"
      className="bg-primary"
      rotate
    />
  </Container>

);

// import React from 'react';

// const Chat = () => (
//   <>
//     <div
//       id="gb-widget-6204"
//       style={{
//         bottom: '21px',
//         right: '23px',
//         opacity: '1',
//         transition: 'opacity 0.5s ease 0s',
//         boxSizing: 'border-box',
//         position: 'fixed !important',
//         zindex: '16000160 !important',
//         direction: 'ltr',
//       }}
//     >
//       <div className="sc-1s18q3d-0 sc-7dvmpp-0 kiuXFD">
//         <div className="q8c6tt-2 jxPOhn">
//           <div className="q8c6tt-1 kMshiB">Facebook Messenger</div>
//
//         </div>
//         <div className="q8c6tt-2 jxPOhn">
//           <div className="q8c6tt-1 kMshiB">Whsssssatsapp</div>
//           <a href="https://wa.me/51959628837?text=Mensake%20de%20prueba" target="_blank" color="#4dc247" id="" className="q8c6tt-0 jlzTty">
//             <svg
//               viewBox="0 0 32 32"
//               style={{
//                 width: '100%',
//                 height: '100%',
//                 fill: 'rgb(255, 255, 255)',
//                 stroke: 'none',
//               }}
//               xmlns="http://www.w3.org/2000/svg"
//               xmlnsXlink="http://www.w3.org/1999/xlink"
// eslint-disable-next-line max-len
//             ><path d="M19.11 17.205c-.372 0-1.088 1.39-1.518 1.39a.63.63 0 0 1-.315-.1c-.802-.402-1.504-.817-2.163-1.447-.545-.516-1.146-1.29-1.46-1.963a.426.426 0 0 1-.073-.215c0-.33.99-.945.99-1.49 0-.143-.73-2.09-.832-2.335-.143-.372-.214-.487-.6-.487-.187 0-.36-.043-.53-.043-.302 0-.53.115-.746.315-.688.645-1.032 1.318-1.06 2.264v.114c-.015.99.472 1.977 1.017 2.78 1.23 1.82 2.506 3.41 4.554 4.34.616.287 2.035.888 2.722.888.817 0 2.15-.515 2.478-1.318.13-.33.244-.73.244-1.088 0-.058 0-.144-.03-.215-.1-.172-2.434-1.39-2.678-1.39zm-2.908 7.593c-1.747 0-3.48-.53-4.942-1.49L7.793 24.41l1.132-3.337a8.955 8.955 0 0 1-1.72-5.272c0-4.955 4.04-8.995 8.997-8.995S25.2 10.845 25.2 15.8c0 4.958-4.04 8.998-8.998 8.998zm0-19.798c-5.96 0-10.8 4.842-10.8 10.8 0 1.964.53 3.898 1.546 5.574L5 27.176l5.974-1.92a10.807 10.807 0 0 0 16.03-9.455c0-5.958-4.842-10.8-10.802-10.8z" />
//             </svg>
//           </a>
//         </div>
//       </div>
//       <div className="q8c6tt-2 jxPOhn">
//         <a color="#E74339" className="q8c6tt-0 iEzLcb">
//           <div className="v2p3h2-0 epNqvL">
//             <svg
//               style={{
//                 width: '100%',
//                 height: '100%',
//                 fill: 'rgb(255, 255, 255)',
//                 stroke: 'none',
//               }}
//               xmlns="http://www.w3.org/2000/svg"
//               xmlnsXlink="http://www.w3.org/1999/xlink"
//               viewBox="-9 -9 41 41"
// eslint-disable-next-line max-len
//             ><path d=" M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
//             </svg>
//           </div>
//         </a>
//       </div>
//     </div>
//   </>
// );

export default Chat;
